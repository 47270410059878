<template>
  <div id="app" ref="cont">
      <!-- <allHead></allHead> -->
      <router-view v-if="isShow" />
  </div>
</template>
<script>
  import axios from 'axios'
  // import allHead from './views/all_head.vue';
  export default {
    name: 'App',
    components: {
      // allHead,
    },
    data(){
      return {
        bdVidList: [
          { host: 'localhost:8080', type: 1 },
        ],
        qhclickidList: [
          { host: 'localhost:8080', type: 1 }
        ],
        clickBtuuonRepeat: false,
        width: window.innerWidth,
        height: window.innerHeight, //window.innerHeight,
        isShow: true,
      }
    }, 
    watch:{
      $route:{
        handler: function () {
          setTimeout(() => {
            if ( this.$route.name != 'About' ) {
              this.track(0,'','')
            }
            if ( ['dll','msvbcrt','About','DllAbout','chaineseChess'].includes(this.$route.name) ) {
              this.isShow = true
            }
          }, 200);
        },
        deep:true,
        // immediate:true
      }
    },
    mounted(){
      let res = this.parseQueryString(window.location.href)
      sessionStorage.setItem('lunchUrl', window.location.href)
      if (res && res.bd_vid) {
        sessionStorage.setItem('tg_id', res.bd_vid)
        sessionStorage.setItem('tg_type', 1)
        let bd_type = this.bdVidList.find((item) => item.host == window.location.host)
        sessionStorage.setItem('tg_url_type', bd_type ? bd_type.type : '')
      } else {
        let bd = sessionStorage.getItem('tg_id')
        let bt = sessionStorage.getItem('tg_url_type')
        let qh = sessionStorage.getItem('tg_type')
        sessionStorage.setItem('tg_id', bd)
        sessionStorage.setItem('tg_url_type', bt)
        sessionStorage.setItem('tg_type', qh)
      }

      if (res && res.qhclickid) {
        sessionStorage.setItem('tg_id', res.qhclickid)
        sessionStorage.setItem('tg_type', 2)
        let qhclickid_type = this.qhclickidList.find((item) => item.host == window.location.host)
        sessionStorage.setItem('tg_url_type', qhclickid_type ? qhclickid_type.type : '')
      } else {
        let bd = sessionStorage.getItem('qhclickid')
        let qh = sessionStorage.getItem('tg_type')
        let bt = sessionStorage.getItem('tg_url_type')
        sessionStorage.setItem('qhclickid', bd)
        sessionStorage.setItem('tg_url_type', bt)
        sessionStorage.setItem('tg_type', qh)
      }
      if (res && res.id) {
        sessionStorage.setItem('pdf_id', res.id ? res.id : '')
      } else {
        let id = sessionStorage.getItem('pdf_id')
        sessionStorage.setItem('pdf_id', id)
      }
      if ( window.location.host == 'youxi.ncdsnc.com' ||  window.location.host == 'yx.yx8668.com') {
        this.isShow = false
        return
      } else {
        this.isShow =  true
      }
      window.addEventListener('resize', () => {
        this.updateScaleRatio()
      })
      this.updateScaleRatio()
      // this.toUrl()
    },
    methods:{

      toUrl(){
        const currentUrl = ['danji.wxqhx.cn','youxi.wxqhx.cn','danji.ggzoom.cn','danji.cqygfxgfst.top','danji.jjdpkh.cn','youxi.wxiskj.cn','youxi.ncdsnc.com','localhost:8080']
        let isUrl = window.location.host
        if ( !currentUrl.includes(isUrl) ) {

          window.top.location.href = 'http://danji.ncdsnc.com/redAlert?id=3'
        }
      },

      toDownload(){
        let res = sessionStorage.getItem('downloadUrl') || ''
        if(res) {
          if(this.clickBtuuonRepeat) return
          const a = document.createElement('a')
          a.href = res
          document.body.appendChild(a)
          a.click()
          a.remove()
        }
        let isCallbackurl = ''
          isCallbackurl = 'https://api.pdf.kafanol.com/pdf/download/callback'
        axios.post(isCallbackurl,{ tg_id: sessionStorage.getItem('tg_id') == 'null' ? '0' : sessionStorage.getItem('tg_id') }).then((res) => {
          console.log(res);
        })
        this.clickBtuuonRepeat = true
        setTimeout(() => {
          this.clickBtuuonRepeat = false
        }, 2000)
      },
      //将url转成对象
      parseQueryString(url) {
        //url参数转对象
        url = !url ? window.location.href : url
        if (url.indexOf('?') === -1) {
          return null
        }
        let search = url[0] === '?' ? url.substr(1) : url.substring(url.lastIndexOf('?') + 1)
        if (search === '') {
          return null
        }
        search = search.split('&')
        let query = {}
        for (let i = 0; i < search.length; i++) {
          let pair = search[i].split('=')
          query[pair[0]] = pair[1] || ''
        }
        return query
      },

      updateScaleRatio() {
        var rect = this.$refs.cont && this.$refs.cont.getBoundingClientRect()
        var ratio = rect.width / rect.height
        var innerRatio = this.width / this.height
        if (ratio < innerRatio) {
          var scaleRatio = rect.width / this.width
          this.currentRatio = scaleRatio >= 1 ? 1 : scaleRatio
        } else {
          scaleRatio = rect.height / this.height
          this.currentRatio = scaleRatio
          this.currentRatio = scaleRatio >= 1 ? 1 : scaleRatio
        }
      },
    }
  }
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-size: 14px;
  background-color: #000;
  // max-width: 2285px;
  // height: 100vh;
}

:root {
  --base_color: #FB4869;
  --base_width: 1184px;
  --base_black: #505361;
  --base_hover: #5e80f8;
  --base_active: #2a47af;
}

#app,
html,
body {
  width:100%;
  position: relative;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  background: #fff;
  padding: 0px;
  margin: 0px;
  min-width: var(--base_width);
}
</style>
