import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'Warcraft',
  //   // redirect: '/firstpage',
  //   meta:{
  //     name:'Warcraft',
  //     title: '魔兽争霸',
  //   },
  //   component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  // },// 魔兽争霸
  {
    path: '/warcraft',
    name: 'Warcraft',
    meta:{
      name:'Warcraft',
      title: '魔兽争霸',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  },// 魔兽争霸
  {
    path: '/redAlert',
    name: 'RedAlert',
    meta:{
      name:'RedAlert',
      title: '红警',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  }, // 红警
  {
    path: '/Acgame',
    name: 'Acgame',
    meta:{
      name:'Acgame',
      title: '街机合集',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  }, // 街机
  {
    path: '/HoMM',
    name: 'HoMM',
    meta:{
      name:'HoMM',
      title: '英雄无敌3',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/otherView.vue')
  },// 英雄无敌
  {
    path: '/CounterStrike',
    name: 'CounterStrike',
    meta:{
      name:'CounterStrike',
      title: '反恐精英',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  }, // 反恐精英
  {
    path: '/StarCraft',
    name: 'StarCraft',
    meta:{
      name:'StarCraft',
      title: '星际争霸',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  },// 星际争霸
  {
    path: '/Diablo',
    name: 'Diablo',
    meta:{
      name:'Diablo',
      title: '暗黑破坏神',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/otherView.vue')
  }, // 暗黑破坏神
  {
    path: '/about',
    name: 'About',
    meta:{
      name:'About',
      title: '攻略下载助手',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  // {
  //   path: '/dllAbout',
  //   name: 'DllAbout',
  //   meta:{
  //     name:'DllAbout',
  //     title: '修复工具安装助手',
  //   },
  //   component: () => import(/* webpackChunkName: "about" */ '../views/dllAbout.vue')
  // },
  // {
  //   path: '/xiangqi',
  //   name: 'chaineseChess',
  //   meta:{
  //     name:'chaineseChess',
  //     title: '攻略下载助手',
  //   },
  //   component: () => import(/* webpackChunkName: "about" */ '../views/xiangqi/xiangqi.vue')
  // },
  {
    path: '/QiangTan',
    name: 'QiangTanDengLu',
    meta:{
      name:'QiangTanDengLu',
      title: '抢滩登陆战',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  }, // 抢滩登陆战,
  {
    path: '/XueZhan',
    name: 'XueZhanShangHaiTan',
    meta:{
      name:'XueZhanShangHaiTan',
      title: '血战上海滩',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  }, // 血战上海滩
  {
    path: '/DiGuo',
    name: 'DiGuo',
    meta:{
      name:'DiGuo',
      title: '帝国时代',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  }, // 帝国时代
  {
    path: '/JingDianHeJi',
    name: 'JingDianHeJi',
    meta:{
      name:'JingDianHeJi',
      title: '经典游戏合集',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  }, // Windows游戏经典合集
  {
    path: '/BaoLiMoTuo',
    name: 'BaoLiMoTuo',
    meta:{
      name:'BaoLiMoTuo',
      title: '暴力摩托',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  }, // 暴力摩托
  {
    path: '/ZuMa',
    name: 'ZuMa',
    meta:{
      name:'ZuMa',
      title: '祖玛',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  }, // 祖玛
  {
    path: '/ShiMingZhaoHuan',
    name: 'ShiMingZhaoHuan',
    meta:{
      name:'ShiMingZhaoHuan',
      title: '使命召唤',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  }, // 使命召唤
  {
    path: '/GuiQi',
    name: 'GuiQi',
    meta:{
      name:'GuiQi',
      title: '鬼泣',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  }, // 鬼泣
  {
    path: '/MountBlade',
    name: 'MountBlade',
    meta:{
      name:'MountBlade',
      title: '骑马与砍杀',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/otherView.vue')
  },// 骑马与砍杀
  {
    path: '/Prototype',
    name: 'Prototype',
    meta:{
      name:'Prototype',
      title: '虐杀原形',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  },// 虐杀原形
  {
    path: '/GTA',
    name: 'GTA',
    meta:{
      name:'GTA',
      title: '侠盗猎车手',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  },// 侠盗猎车手
  // {
  //   path: '/dll',
  //   name: 'dll',
  //   meta:{
  //     name:'dll',
  //     title: '修复工具',
  //   },
  //   component: () => import(/* webpackChunkName: "about" */ '../views/dllView.vue')
  // },// 工具
  // {
  //   path: '/msvbcrt',
  //   name: 'msvbcrt',
  //   meta:{
  //     name:'msvbcrt',
  //     title: '修复工具',
  //   },
  //   component: () => import(/* webpackChunkName: "about" */ '../views/msvbcrtView.vue')
  // },// 工具
  {
    path: '/ResidentEvil',
    name: 'ResidentEvil',
    meta:{
      name:'ResidentEvil',
      title: '生化危机',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  },// 生化危机
]


const router = new VueRouter({
  mode: 'history',
  // base: '/web',
  routes
})

router.afterEach((to, from) => {
  if (to.meta.name) {
    var favIco = document.getElementById('favicon')
    var title = document.getElementById('docTitle')
    favIco.href = '/static/' + to.meta.name + '.ico'
    title.innerText =  to.meta.title
  }
})


export default router
